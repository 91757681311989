export const BtnStart = () => {
    return (
        <div>
            <a href='/#form' className="pr-5">
                <button className="text-white btnSuccess">INICIAR</button>
            </a>
            <a href='/#funil'>
                <button className="text-white btnAbout">LER SOBRE</button>
            </a>
        </div>
    );
}